import './App.css';

function App() {
  return (
    <div className="App">
      <h1 className="placeholder">Admin Site</h1>
    </div>
  );
}

export default App;
